.gallery {
  display: block;
  padding: 16px;
  text-align: center; /* Zentriert den Inhalt horizontal */
  background-color: #f5f5f5; /* Heller Hintergrund für die Galerie (Standard) */
}

.gallery-item {
  width: 33%; /* Nimmt 33% der Containerbreite auf größeren Bildschirmen ein */
  margin: 0 auto 16px auto; /* Zentriert das Element und fügt unten Abstand hinzu */
  overflow: hidden;
  background-color: transparent; /* Kein Hintergrund für die Gallery-Items */
}

.gallery img {
  width: 100%; 
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Standard Schatten */
  display: block; /* Verhindert zusätzliche Leerzeichen */
}

/* Media Query für Mobilgeräte */
@media (max-width: 768px) {
  .gallery-item {
    width: 90%; /* Nimmt 90% der Containerbreite auf kleineren Bildschirmen ein */
  }
}

/* Dark Mode Styles */
@media (prefers-color-scheme: dark) {
  .gallery {
    background-color: #121212; /* Dunkler Hintergrund für die Galerie */
    color: #ffffff; /* Heller Text */
  }

  .gallery-item {
    background-color: transparent; /* Kein Hintergrund für die Gallery-Items im Dark Mode */
  }

  .gallery img {
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Hellerer Schatten im Dark Mode */
  }
}
